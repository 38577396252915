import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import AdminService from "../../services/admin.service";
const clock = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" className="bi bi-clock"
         viewBox="0 0 16 16">
        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
    </svg>
)

const Events = () => {
    const [events, setEvents] = useState([]);
    useEffect(() => {
        AdminService.eventCalendar_get(process.env.SCHOOL_NAME)
            .then((res => {
                if (res?.data?.length){
                    setEvents(res.data);
                }
            })).catch((e) => {
            return;
        })
    }, [])

    if (events?.length)
        return (
        <div className='container-custom'>
            <div className='fancy'>
                <span>Upcoming events</span>
            </div>
            <div className='contentEvent'>
                <div className='events'>
                    {events.map((object, key) => {
                        return (
                            <div className='event-card' key={key}>
                                <div className='date'>{moment(object.date).format('ddd, MMM')}</div>
                                <div className='day'>{moment(object.date).format('DD')}</div>
                                <div className='title'>{object.title}</div>
                                <div className='hour'>
                                    {clock}
                                    {moment(object.date).format('hh:mm')}
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
    return null;
}

export default Events;