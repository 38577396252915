import React, {useState, useEffect} from 'react';
import {InView} from "react-intersection-observer";
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import GraduateIcon from './../assets/svg/icons/graduate-icon@2x.png'
import '../../translation';
import LogoNoText from './../assets/svg/logo/logo-notext-fill.svg'
import {useTranslation} from 'react-i18next';
import BackgroundHomeTextIcons from './../assets/images/topHeaderImages/new/statements-area-bg.jpg'
import Diplome from '../assets/images/home/chapeau-diplome.png'
import Events from "../components/Front/Events";


const Index = ({data}) => {
    const [earthAnim, setEarthAnim] = useState('');
    const [titleFadeIn, setTitleAnim] = useState('');
    const [titleFadeIn2, setTitleAnim2] = useState('');
    const [titleFadeIn3, setTitleAnim3] = useState('');
    const [colAnim, setColAnim] = useState('');
    const {t} = useTranslation('accueil');
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])


    return (
        <>
            <Navigation page='/'/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Collège Lycée International Bilingue Lafayette Nice</title>
                <meta name="description"
                      content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi."/>
            </Helmet>

            <div className='container-custom'>
                <InView
                    as="div"
                    style={{opacity: 0, animationDelay: 0}}
                    className={`container-custom ${titleFadeIn3}`}
                    onChange={(inView, entry) => {
                        inView && setTitleAnim3('titleFadeIn')
                    }}
                    threshold={0.3}
                >
                    <div className='home-title'>
                        <img alt='diplome' src={Diplome}/>
                        <h3>{t('t1')}</h3>
                    </div>
                    <p className='mt-4' dangerouslySetInnerHTML={{__html: t('p1')}}/>
                </InView>
            </div>

            <Events />


            {/*HOME HERO CONTAINER ------------------------*/}
            <div className='homeHero' style={{
                background: `linear-gradient(20deg, rgba(65, 65, 65, 0.67), rgb(5, 17, 41)), url(${BackgroundHomeTextIcons})`,
                backgroundPosition: 'bottom center',
                backgroundSize: 'cover'
            }}>
                <div className='overlay'></div>
                <div className='inner'>
                    <div className='container'>
                        <h4 dangerouslySetInnerHTML={{__html: t('t2')}}>
                        </h4>
                        <div>
                            <img src={GraduateIcon} alt='icon'/>
                            {t('p2')}
                        </div>
                        <div>
                            <img src={GraduateIcon} alt='icon'/>
                            {t('p3')}
                        </div>
                        <div>
                            <img src={GraduateIcon} alt='icon'/>
                            {t('p4')}
                        </div>
                        <div>
                            <img src={GraduateIcon} alt='icon'/>
                            {t('p5')}
                        </div>
                        <div>
                            <img src={GraduateIcon} alt='icon'/>
                            {t('p6')}
                        </div>
                    </div>

                </div>
            </div>
            {/* ------------------------*/}

            {/* EARTH SECTION ------------------------*/}
            <section className='container-custom'>
                <div className='home-title'>
                    <img alt='diplome' src={Diplome}/>
                    <h3 dangerouslySetInnerHTML={{__html: t('t3')}}/>
                </div>
                <p className='pEarthSection'>
                    <img src={LogoNoText} />
                    <strong>Lafayette International School</strong> offre une rigoureuse éducation Bilingue, une
                    diversité culturelle et un environnement positif et créatif.<br/>

                    <strong>Lafayette International School</strong> construit la confiance en soi et développe une
                    pensée structurée et indépendante qui élargit les horizons et offre à chaque élève de développer une
                    pensée critique et structurée.<br/>

                    <strong>Lafayette International School</strong> élargit les horizons en explorant le monde,
                    découvrant ainsi des perspectives différentes.<br/>

                    <strong>Lafayette International School</strong> prépare à la réussite pour accéder à toutes les
                    universités en France et à l'Etranger.<br/>
                </p>
                <InView
                    as="div"
                    className='earth-titles'
                    onChange={(inView) => {
                        inView && setEarthAnim('fadeInEarth')
                    }}
                    threshold={0.3}
                >
                    <div className={`earth ${earthAnim}`}>
                        {t('earth-1')}
                    </div>
                    <div className={`earth ${earthAnim}`}>
                        {t('earth-3')}
                    </div>
                    <div className={`earth ${earthAnim}`}>
                        {t('earth-4')}
                    </div>
                    <div className={`earth ${earthAnim}`}>
                        {t('earth-5')}
                    </div>
                </InView>
                <p
                    style={{animationDelay: '0.5s'}}
                    className={`${titleFadeIn} mt-5`}
                    dangerouslySetInnerHTML={{__html: t('t3p')}}
                >
                </p>


            </section>
            {/* ------------------------*/}

            <Footer/>
        </>
    )
}

export default Index;

